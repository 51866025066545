import * as UploadingFile from './uploadingFile.json'
import * as Meetings from './meetings.json'
import * as NoData from './no-data-error.json'
import * as QuestionBoy from './question-boy.json'
import * as Trash from './trash-clean.json'
import * as Bye from './bye.json'
import * as Hi from './hi.json'
import * as Lock from './lock.json'
import * as Software from './software.json'
import * as OfficeWork from './office-work.json'
import * as PaymentFailedError from './payment-failed-error.json'
import * as Calendar from './calendar.json'
export { UploadingFile, Meetings, NoData, QuestionBoy, Trash, Bye, Hi, Lock, Software, OfficeWork, PaymentFailedError, Calendar }